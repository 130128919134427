import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './Store'

import 'semantic-ui-less/semantic.less'
import 'moment/locale/es'

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

if (window.cordova) {
  document.addEventListener('deviceready', () => startApp(), false);
} else {
  startApp();
  registerServiceWorker();
}

function startApp() {
  ReactDOM.render(<Root />, document.getElementById('root'));
}

class EpsonDevice {
  constructor() {
    if (epson && epson.ePOSDevice) {
      this.device = new epson.ePOSDevice()
    }
    this.printer = null
  }

  print(ip, ssl, force) {
    return new Promise((resolve, reject) => {
      if (!this.device) {
        return reject('No hay impresora')
      }
      if (this.printer !== null && this.device.isConnected() && !force) {
        return resolve(this.printer)
      }
      let port = ssl ? '8043' : '8008'
      console.log('connecting', ip, port)
      this.device.connect(ip, port, data => this._onConnect(data, resolve, reject), {"eposprint" : true})
    })
  }

  _onConnect = (data, resolve, reject) => {
    console.log('onConnect', data)
    if(data === 'OK') {
      this.device.createDevice('local_printer', this.device.DEVICE_TYPE_PRINTER, {'crypto' : false, 'buffer' : false}, (printer, code) => this._onCreated(printer, code, resolve, reject))
    } else {
      reject('No se pudo conectar con la impresora')
    }
  }

  _onCreated = (printer, code, resolve, reject) => {
    console.log('onCreated', code)
    if (code === 'OK' || code === 'SSL_CONNECT_OK') {
      this.printer = printer
      resolve(printer)
    } else {
      reject(code);
    }
  }
}



export default new EpsonDevice()


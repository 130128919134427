import { CREATE_SESSION, DESTROY_SESSION, FIJAR_SESSION } from './Types'
import { login, logout, obtenerSesion } from '../api'

export function sessionLogin(data) {
  return (dispatch) => login(data).then(r => {
    if (r.status === 'success') {
      dispatch({
        type: CREATE_SESSION,
        token: r.data,
        email: data.email
      })
    }
    return r
  })
}

export const sessionLogout = () => (dispatch) => logout().finally(() =>
  dispatch(destroySession())
)

export function refrescarSession() {
  return (dispatch) => obtenerSesion().then(res => autorizado(dispatch, res)).then(res => {
    dispatch({
      type: FIJAR_SESSION,
      ...res.data
    })
    return res
  })
}

export function autorizado(dispatch, res) {
  if (res.status === 'auth') {
    dispatch(destroySession())
    return Promise.reject(res.data)
  }
  return res
}

function destroySession() {
  return {
    type: DESTROY_SESSION
  }
}
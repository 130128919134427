import React from 'react';
import { connect } from "react-redux";

import moment from 'moment'
import { int, clp, isEmpty, str, is_nori, reduceProducts } from '../../utils'
import { fetchGastosJornada, isSuccess, cerrarJornada } from '../../api';
import { COMPLETED, CANCELED } from '../../utils/Status';
import { EFECTIVO, TRANSBANK, SODEXO, TRANSFERENCIA, APLICACIONES, payment_label } from '../../utils/PaymentTypes';
import { Confirm, Container, Grid, Header, Icon, Item, Label, Menu, Message, Segment, Statistic, Tab } from 'semantic-ui-react';
import { is_window_medium } from '../../reducers';
import { DELIVERY, is_delivery, typeIcon } from '../../utils/OrderTypes';
import { DETALLE_PEDIDO } from '../../utils/Routes';
import { fijarJornadaActiva, fijar_jornada_detalle } from '../../actions';
import { pedidos_jornada_from_state } from '../../reducers/JornadaReducer';
import GastoItem from '../../components/GastoItem';
import { MensajeError } from '../../components/Mensajes';
import { permisos_fs } from '../../reducers/SessionReducer';
import Screen from '../Screen';
import { AppHeader } from '../../components';
import PedidoItem from '../../components/PedidoItem';



class DetalleJornada extends Screen {
  state = {
    gastos: [],
    loading: true,
    network: false,
    confirm: false
  }

  async componentDidMount() {
    try {
      const id = int(this.props.match.params.id)
      await this.props.fijar_jornada_detalle(id)
      const res2 = await fetchGastosJornada(id)
      if (isSuccess(res2)) {
        this.setState({ gastos: res2.data })
      }
    } catch (e) {
      console.log('[componentDidMount]', e)
    } finally {
      this.setState({ loading: false })
    }
  }

  networking = () => this.setState({ network: !this.state.network })

  cerrar = () => {
    this.toggleConfirm()
    this.networking()
    cerrarJornada(this.props.match.params.id).then(res => {
      if (isSuccess(res)) {
        this.props.fijarJornadaActiva()
        this.volver()
      } else {
        this.setState({ error: res.data })
      }
    }).catch(console.log).finally(this.networking)
  }

  modificar = (_id) => {
    console.log('mod',_id)
    this.props.history.push(DETALLE_PEDIDO.replace(":id", _id))
  }

  volver = () => this.props.history.goBack()
  toggleConfirm = () => this.setState({ confirm: !this.state.confirm })

  render() {
    const { gastos, error, network, loading, confirm } = this.state
    const { jornadas, jornada_activa, match, pedidos, operador } = this.props
    const id = int(match.params.id)
    const jornada = jornadas.find(j => j.id === id)
    const created = moment(jornada.apertura)
    const closed = jornada.cierre !== null ? moment(jornada.cierre) : null
    const disabled = network || closed !== null || loading

    const completados = pedidos.filter(pedidoCompletado)
    const total_delivery = completados.reduce((t, v) => t + v.deliveryCost, 0)

    const gastos_totales = [ ...gastos]
    if (total_delivery > 0) {
      gastos_totales.push({
        id: 'gastos',
        monto: total_delivery,
        cantidad: 1,
        caja: true,
        concepto: { nombre_concepto: 'Repartidores'}
      })
    }

    return (
      <div>
        <AppHeader>
          <Menu.Item onClick={this.volver}>
            <Icon name="chevron left" size="big" />
            Volver
          </Menu.Item>
          <Menu.Item>
            <div>
              Jornada
              <p>{id}</p>
            </div>
          </Menu.Item>
          {operador && <Menu.Item position="right" onClick={this.toggleConfirm} disabled={disabled}>
            <Icon name="ban" size="big" />
            Cerrar Jornada
          </Menu.Item>}
        </AppHeader>
        <Container>
          <Confirm open={confirm} onCancel={this.toggleConfirm} onConfirm={this.cerrar}
            content={`Seguro quieres cerrar la jornada ${id}?`} cancelButton="Volver" confirmButton="Cerrar Jornada" />
          <MensajeError content={error} />
          {jornada_activa === id ? <Active /> : null}
          {closed !== null ? <Closed /> : null}
          <Message>
            Fecha de Apertura: <strong>{created.format("dddd DD MMMM, HH:mm")}</strong>
          </Message>
          {closed !== null && 
          <Message>
            Fecha de Cierre: <strong>{closed.format("dddd DD MMMM, HH:mm")}</strong>
          </Message>
          }
          <Totales pedidos={completados} gastos={gastos_totales} busy={loading} small={this.props.small} />
          <AcordionGroup pedidos={pedidos} gastos={gastos_totales} small={this.props.small}
            busy={loading} onModificarClick={!closed && this.modificar}/>
        </Container>
      </div>
    )
  }
}

function Totales({ pedidos, gastos, busy, small }) {
  const efectivo = pedidos.filter(({ payment }) => payment === EFECTIVO).reduce(reduceProductos, 0)
  const otros = pedidos.filter(({ payment }) => payment !== EFECTIVO).reduce(reduceProductos, 0)
  const tarjetas = pedidos.filter(({ payment }) => payment === TRANSBANK).reduce(reduceProductos, 0)
  const sodexo = pedidos.filter(({ payment }) => payment === SODEXO).reduce(reduceProductos, 0)
  const transferencias = pedidos.filter(({ payment }) => payment === TRANSFERENCIA).reduce(reduceProductos, 0)
  const aplicaciones = pedidos.filter(({ payment }) => payment === APLICACIONES).reduce(reduceProductos, 0)

  const total_gastos = gastos.filter(g => g.caja).reduce(reduceGastos, 0)

  return (
    <div>
      <Segment loading={busy}>
        <Statistic.Group widths={small ? 1 : 3} size="mini">
          <Statistic>
            <Statistic.Value>{clp(tarjetas)}</Statistic.Value>
            <Statistic.Label>Tarjetas</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{clp(sodexo)}</Statistic.Value>
            <Statistic.Label>Sodexo</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{clp(transferencias)}</Statistic.Value>
            <Statistic.Label>Transferencias</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{clp(aplicaciones)}</Statistic.Value>
            <Statistic.Label>Aplicaciones</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
      <Segment loading={busy}>
        <Statistic.Group widths={small ? 1 : 3} size="mini">
          <Statistic>
            <Statistic.Value>{clp(efectivo)}</Statistic.Value>
            <Statistic.Label>(1) Total Pedidos en Efectivo</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{clp(otros)}</Statistic.Value>
            <Statistic.Label>(2) Total Pedidos Otros medios de pago</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{clp(total_gastos)}</Statistic.Value>
            <Statistic.Label>(3) Total Gastos</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
      <Segment loading={busy}>
        <Statistic.Group widths={small ? 1 : 2} size="mini">
          <Statistic>
            <Statistic.Value>{clp(efectivo - total_gastos)}</Statistic.Value>
            <Statistic.Label>Total Caja (1) - (3)</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{clp(efectivo + otros)}</Statistic.Value>
            <Statistic.Label>Total Ventas (1) + (2)</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
    </div>
  )
}


function AcordionGroup({ pedidos, gastos, small, onModificarClick, busy }) {
  const pedidos_terminados = pedidos.filter(pedidoCompletado)
  const pedidos_anulados = pedidos.filter(({ status }) => status === CANCELED)

  const pedidos_delivery = pedidos_terminados.filter(v => v.type === DELIVERY && v.deliveryMan && v.deliveryMan !== null)
  let repartidores = []
  
  for (let v of pedidos_delivery) {
    const man = v.deliveryMan
    let despacho = v.deliveryCost
    let cantidad = 1
  
    const repartidor = repartidores.find(v => v.email === man.email)
    if (repartidor) {
      despacho += repartidor.total
      cantidad += repartidor.cantidad
      repartidores = repartidores.filter(v => v.email !== man.email)
    }
    repartidores.push({
      email: man.email,
      nombre: man.nombre,
      total: despacho,
      cantidad
    })
  }

  const panes = [
    {
      menuItem: (
        <Menu.Item key='facturados'>
          Facturados<Label color="blue">{pedidos_terminados.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Grid divided="vertically" doubling={small}>
          {pedidos_terminados.sort(sortPedidos).map(v => <PedidoItem key={v.id || v._id} {...v} small={small} onClick={onModificarClick} />)}
        </Grid>
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='anulados'>
          Anulados<Label color="blue">{pedidos_anulados.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Grid divided="vertically">
          {pedidos_anulados.sort(sortPedidos).map(v => <PedidoItem key={v.id || v._id} {...v} small={small} />)}
        </Grid>
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='gastos'>
          Gastos<Label color="blue">{gastos.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Item.Group divided>
          {gastos.map(v => <GastoItem key={v.id} {...v} />)}
        </Item.Group>
      </Tab.Pane>
    },
    {
      menuItem: (
        <Menu.Item key='repartidores'>
          Repartos<Label color="blue">{repartidores.length}</Label>
        </Menu.Item>
      ),
      render: () => <Tab.Pane loading={busy}>
        <Item.Group divided>
          {repartidores.map(v => <Repartidor key={v.email} { ...v } />)}
        </Item.Group>
      </Tab.Pane>
    },
  ]

  return (
    <Tab panes={panes} className="cs-mt-3" 
      menu={{ attached: !small, tabular: !small, stackable: true, widths: 4 }} />
  )
}

const Repartidor = ({ email, nombre, total, cantidad }) => {
  return (
    <Item>
      <Item.Content>
        <Item.Header>{nombre}</Item.Header>
        <Item.Meta>{email}</Item.Meta>
        <Item.Description>{clp(total)}</Item.Description>
      </Item.Content>
    </Item>
  )
}

const Active = () => (
  <Message color="green">
    <strong>Esta jornada esta activa.</strong>
  </Message>
)

const Closed = () => (
  <Message color="yellow">
    <strong>Esta jornada esta cerrada.</strong>
  </Message>
)

function pedidoCompletado({ status }) {
  return status === COMPLETED
}

function reduceProductos(t, v) {
  return t + (v.products.reduce(reduceProducts, 0))
}

function reduceGastos(t, v) {
  return t + (v.cantidad * v.monto)
}

function sortPedidos(a, b) {
  return a.index - b.index
}

const mapStateToProps = (state) => ({
  jornadas: state.jornadas,
  jornada_activa: state.jornada_activa,
  pedidos: pedidos_jornada_from_state(state),
  small: is_window_medium(state),
  operador: permisos_fs(state) >= 1024
})

export default connect(mapStateToProps, { fijar_jornada_detalle, fijarJornadaActiva })(DetalleJornada)
import React, { Component } from 'react';

import {int, isEmpty, MAX_NUMBER, clp} from '../../utils'
import { isSuccess, crearGasto } from '../../api';
import { Button, Checkbox, Container, Form, Header, Icon, Input, Menu, Select } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { categorias_fs, conceptos_fs } from '../../reducers';
import { fijar_conceptos } from '../../actions';


class IngresarCompra extends Component {
  state = {
    id_categoria: 1,
    concepto: '',
    descripcion: '',
    monto: '',
    cantidad: '1',
    caja: true,
    loading: false
  }

  async componentDidMount() {
    await this.props.fijar_conceptos()
  }

  create = (e) => {
    e.preventDefault()
    const gasto = {
      descripcion: this.state.descripcion.trim(),
      monto: int(this.state.monto),
      cantidad: int(this.state.cantidad),
      //concepto: this.state.conceptos.find(c => c.id_concepto === int(this.state.concepto)),
      caja: this.state.caja,
      id_concepto: this.state.concepto
    }
    this.loading()
    crearGasto(gasto).then(res => {
      if (isSuccess(res)) {
        this.volver()
      }
    }).catch(console.error).finally(this.loading)
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value})
  }

  onChecked = (e) => this.setState({ [e.target.id]: e.target.checked })

  onCategoria = (e, { value }) => this.setState({ id_categoria: value, concepto: '' })
  onConcepto = (e, { value }) => this.setState({ concepto: value })

  volver = () => this.props.history.goBack()
  loading = () => this.setState({ loading: !this.state.loading })

  render() {
    const { monto, cantidad, id_categoria, concepto, descripcion, caja, loading } = this.state
    const conceptos = this.props.conceptos.filter(c => c.id_categoria === id_categoria)
    let total = int(monto) * int(cantidad)
    total = isNaN(total) ? 0 : total

    const disabled = isNaN(monto) || concepto === '' ||
      isNaN(cantidad) || total > MAX_NUMBER || total === 0 || loading

    return (
      <div>
        <Menu size='massive' inverted icon="labeled" fixed="top" fluid>
          <Menu.Item onClick={this.volver}>
            <Icon name="chevron left" size="big" />
            Volver
          </Menu.Item>
          <Menu.Item position="right" onClick={this.create} disabled={disabled}>
            <Icon name="save" size="big" />
            Guardar
          </Menu.Item>
        </Menu>
        <Container>
          <Form size="big">
            <Form.Field control={Select} label="Categoria" options={this.props.categorias.map(mapCategoria)} value={id_categoria} onChange={this.onCategoria} />
            <Form.Field control={Select} label="Concepto" options={conceptos.map(mapConcepto)} value={concepto} onChange={this.onConcepto} />
            <Form.Field control={Input} label="Descripción" value={descripcion} id="descripcion" onChange={this.onChange} />
            <Form.Field control={Input} label="Cantidad" value={cantidad} id="cantidad" onChange={this.onChange} type="number" />
            <Form.Field control={Input} label="Monto" value={monto} id="monto" onChange={this.onChange} type="number" />
            <Form.Field control={Checkbox} label="Incluir en caja" checked={caja} onChange={this.onChecked} id="caja" />
            <Button primary fluid size="big" onClick={this.create} loading={loading} disabled={disabled}>Guardar ({clp(total)})</Button>
          </Form>
        </Container>
      </div>
    )
  }
}

const mapConcepto = (c) => ({ key: c.id, value: c.id, text: c.nombre })
const mapCategoria = (c) => ({ key: c.id, value: c.id, text: c.nombre })

const mapStateToProps = (state) => ({
  conceptos: conceptos_fs(state),
  categorias: categorias_fs(state)
})

export default connect(mapStateToProps, { fijar_conceptos })(IngresarCompra)
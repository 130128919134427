import isElectron from "is-electron";
const { ipcRenderer } = isElectron() && window.require('electron')

export function electron_imprimir(target, data) {
  const args = {
    target, data
  }

  ipcRenderer.invoke('print-thermal-action', args)
}

export function electron_native_imprimir(target, data) {
  const args = {
    target, data
  }

  ipcRenderer.invoke('print-electron-action', args)
}

export function electron_impresoras() {
  return new Promise((res, rej) => {
    ipcRenderer.on('print-impresoras-reply', (event, arg) => {
      res(arg)
    })

    ipcRenderer.send('print-impresoras')
  })
}

export function electron_impresoras_map(v) {
  return {
    key: v.name,
    text: v.displayName,
    value: v.name
  }
}
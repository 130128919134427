export const DELIVERY = "DELIVERY"
export const LOCAL = "RETIRO"
export const RETIRO = "LOCAL"
export const RESTAURANT = "RESTAURANT"

export const TYPE_LIST = [{
  id: DELIVERY,
  label: 'Despacho',
  icon: 'motorcycle',
  color: 'cs-color-delivery'
},
{
  id: LOCAL,
  label: 'Retiro',
  icon: 'home',
  color: 'cs-color-retiro'
},
{
  id: RESTAURANT,
  label: 'Restaurant',
  icon: 'utensils',
  color: 'cs-color-retiro'
},
{
  id: RETIRO,
  label: 'Retiro',
  icon: 'home',
  color: 'cs-color-retiro'
}]

export function typeColor(type) {
  return TYPE_LIST.find(t => t.id === type).color
}

export function typeIcon(type) {
  const value = TYPE_LIST.find(v => v.id === type)
  return value ? value.icon : type
}

export function typeLabel(type) {
  const value = TYPE_LIST.find(v => v.id === type)
  return value ?  value.label : ''
}

export function is_delivery(type) {
  return type === DELIVERY
}
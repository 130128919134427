import React from 'react'
import { connect } from 'react-redux';
import { Menu, Icon } from "semantic-ui-react";
import { sucursal_from_state } from '../reducers';
import { jornada_activa_fs } from '../reducers/JornadaReducer';
import { usuario_sucursales_fs } from '../reducers/SessionReducer';
import { react_app_inverted } from '../utils';

import { CONFIGURATION, DASHBOARD, COMPRAS, JORNADAS, SUCURSALES, JORNADAS_DETAIL, JORNADA_ACTUAL } from '../utils/Routes'

function Footer({ history, location, nombre_sucursal, muchas_sucursales, jornada_activa }) {
  const go = (v) => history.push(v)
  const path = location.pathname
  const jornada_activa_detalle = JORNADA_ACTUAL.replace(":id", jornada_activa)
  let widths = 6

  if (jornada_activa === null) {
    widths -= 1
  }

  return (
    <Menu fixed="bottom" fluid inverted={react_app_inverted()} color={process.env.REACT_APP_PRIMARY_COLOR} icon="labeled" widths={widths}>
      <FooterItem label="Pedidos" icon="inbox" active={path === DASHBOARD} onClick={() => go(DASHBOARD)} />
      <FooterItem label="Gastos" icon="book" active={path === COMPRAS} onClick={() => go(COMPRAS)}/>
      {jornada_activa !== null && <FooterItem label="Detalle" icon="clipboard" active={path === JORNADA_ACTUAL} onClick={() => go(jornada_activa_detalle)}/>}
      <FooterItem label="Jornadas" icon="balance scale" active={path === JORNADAS} onClick={() => go(JORNADAS)}/>
      <FooterItem label="Ajustes" icon="cog" active={path === CONFIGURATION} onClick={() => go(CONFIGURATION)}/>
      <FooterItem label={nombre_sucursal} icon="exchange" active={path === SUCURSALES} onClick={() => go(SUCURSALES)} disabled={!muchas_sucursales} />
    </Menu>
  )
}

const FooterItem = ({ label, icon, active, onClick, disabled }) => (
  <Menu.Item active={active} onClick={disabled ? undefined : onClick}>
    <Icon name={icon} />
    {label}
  </Menu.Item>
)

const mapStateToProps = (state) => {
  const sucursal = sucursal_from_state(state)
  return {
    nombre_sucursal: sucursal ? sucursal.nombre : '',
    muchas_sucursales: usuario_sucursales_fs(state).length > 1,
    jornada_activa: jornada_activa_fs(state)
  } 
}

export default connect(mapStateToProps)(Footer)
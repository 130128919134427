export const CREATE_SESSION = 'CREATE_SESSION'
export const DESTROY_SESSION = 'DESTROY_SESSION'
export const FIJAR_SESSION = 'FIJAR_SESSION'

export const SET_SUCURSAL = 'SET_SUCURSAL'
export const SET_PRODUCTOS = 'SET_PRODUCTOS'

export const SET_PEDIDOS = 'SET_PEDIDOS'
export const AGREGAR_PEDIDO = 'AGREGAR_PEDIDO'

export const FIJAR_ERROR = 'FIJAR_ERROR'
export const LIMPIAR_ERROR = 'LIMPIAR_ERROR'

export const FIJAR_JORNADAS = 'FIJAR_JORNADAS'
export const FIJAR_JORNADA_ACTIVA = 'FIJAR_JORNADA_ACTIVA'

export const FIJAR_EMPLEADOS = 'FIJAR_EMPLEADOS'
export const FIJAR_PRODUCTO_FAVORITO = 'FIJAR_PRODUCTO_FAVORITO'
export const FIJAR_WINDOWS_SIZE = 'FIJAR_WINDOWS_SIZE'

export const FIJAR_JORNADA_DETALLE = 'FIJAR_JORNADA_DETALLE'

export const FIJAR_PRINTER_TARGET = 'FIJAR_PRINTER_TARGET'
export const FIJAR_PRINTER_QUANTITY = 'FIJAR_PRINTER_QUANTITY'
export const FIJAR_PRINTER_MODEL = 'FIJAR_PRINTER_MODEL'
export const TOGGLE_PRINTER_SSL = 'TOGGLE_PRINTER_SSL'
export const FIJAR_PRINTER_MODO = 'FIJAR_PRINTER_MODO'
export const FIJAR_PRINTER_NOMBRE = 'FIJAR_PRINTER_NOMBRE'

export const FIJAR_CONCEPTOS = 'FIJAR_CONCEPTOS'
export const FIJAR_FILTRO_ESTADO = 'FIJAR_FILTRO_ESTADO'
export const EFECTIVO = 0
export const TRANSBANK = 1
export const TRANSFERENCIA = 2
export const SODEXO = 3
export const APLICACIONES = 4
export const PAGO_ONLINE = 5
export const AMIPASS = 6

export function paymentLabel(payment) {
  switch (payment) {
    case TRANSBANK: return "fal fa-credit-card fa-2x"
    case TRANSFERENCIA: return "fal fa-university fa-2x"
    default: return "fal fa-money-bill fa-2x"
  }
}

export const mediosDePago = [
  {
    key: EFECTIVO,
    value: EFECTIVO,
    text: "Efectivo"
  },
  {
    key: TRANSBANK,
    value: TRANSBANK,
    text: "Tarjeta"
  },
  {
    key: TRANSFERENCIA,
    value: TRANSFERENCIA,
    text: "Transferencia"
  },
  {
    key: SODEXO,
    value: SODEXO,
    text: "Sodexo"
  },
  {
    key: APLICACIONES,
    value: APLICACIONES,
    text: "Uber Eats"
  },
  {
    key: PAGO_ONLINE,
    value: PAGO_ONLINE,
    text: "Pago Online"
  },
  {
    key: AMIPASS,
    value: AMIPASS,
    text: "Amipass"
  }
]

export function payment_label(key) {
  const value = mediosDePago.find(v => v.key === key)
  return value ? value.text : key
}
import moment from 'moment'
import React, { useState } from 'react'
import { Button, Card, Header, Icon, Label, Segment } from 'semantic-ui-react'
import { isEmpty, is_nori, str } from '../utils'
import { typeIcon, typeLabel, DELIVERY as DELIVERY_TYPE } from '../utils/OrderTypes'
import { statusBgColor, statusColor } from '../utils/Status'

const TarjetaPedido = (props) => {
  const { _id, index, type, user, datetime, channel, pay, status, onClick, onPagarClick, onCancelClick,
    onForwardClick, disabled, deliveryMan } = props

  const [bside, setBside] = useState(false)
  const [busy, toggle] = useState(false)
  const cardClick = () => onClick(_id)
  const toggleBSide = () => setBside(!bside)
  const anularClick = () => onCancelClick(_id)

  const pagar = async () => {
    try {
      toggle(true)
      await onPagarClick(_id)
    } catch (e) {
      console.error(e)
    } finally {
      toggle(false)
    }
  }

  const avanzar = async () => {
    try {
      toggle(true)
      const res = await onForwardClick(_id, status, type)
    } catch (e) {
      console.error(e)
    } finally {
      toggle(false)
    }
  }

  if (bside) {
    return <BSide toggle={toggleBSide} index={index} status={status} detalle={cardClick} anular={anularClick} />
  }

  return (
    <Segment color={statusColor(status)} inverted style={{ padding: 8}}>
      <Segment.Group horizontal style={{ marginTop: 0 }}>
        <Segment compact textAlign="center" style={{ padding: 8 }} onClick={disabled ? undefined : toggleBSide}>
          <Icon name={typeIcon(type)} size="large" />
        </Segment>
        <BotonEstado onClick={disabled ? undefined : avanzar}/>
      </Segment.Group>
      <Segment textAlign="center" style={{ padding: 4 }} onClick={disabled ? undefined : toggleBSide}>
        <h3 style={{marginBottom: 0}}>{isEmpty(user.address) ? '....' : user.address}</h3>
        <small>{isEmpty(user.name) ? 'Anonimo' : `${user.name} ${str(user.apellido)}`}</small>
        <p><small>{moment(datetime).fromNow()}</small></p>
        <p>{(type === DELIVERY_TYPE && deliveryMan && deliveryMan !== null) ? <strong>Despacho: {deliveryMan.nombre}</strong> : null}</p>
      </Segment>
      {!disabled && <BotonPagar color={pay ? 'green' : 'red'} onClick={pagar} label={pay ? 'Pagado' : 'Pagar'}
        disabled={pay || busy} loading={busy} />}
    </Segment>
  )
}

const BSide = ({ index, toggle, status, detalle, anular }) => {
  return (
    <Segment color={statusColor(status)} inverted style={{ padding: 8 }}>
      <Segment.Group horizontal >
        <Segment compact onClick={toggle} textAlign="center">
          <Icon name="arrow left" size="large" />
        </Segment>
        <Segment compact textAlign="center">
          <Header>{index}</Header>
        </Segment>
      </Segment.Group>
      <Segment.Group horizontal >
        <Segment compact textAlign="center" onClick={anular}>
          <Icon name="minus circle" color="red" size="large" />
        </Segment>
        <Segment compact textAlign="center" onClick={detalle}>
          <Icon name="list" size="large" />
        </Segment>
      </Segment.Group>
    </Segment>
  )
}

const BotonEstado = ({ onClick }) => {
  const [ok, setOk] = useState(false)
  const toggle = () => {
    setOk(true)
    setTimeout(() => setOk(false), 2000)
  }
  const continuar = () => {
    if (ok) {
      onClick()
      setOk(false)
    } else {
      toggle()
    }
  }

  return (
    <Segment compact textAlign="center" style={{ padding: 8 }} onClick={onClick ? continuar : undefined}>
      {ok ? <h3>ok?</h3> : <Icon name="check" size="large" color="green" />}
    </Segment>
  )
}

const BotonPagar = ({ label, color, onClick, disabled, loading }) => {
  const [ok, setOk] = useState(false)
  const toggle = () => {
    setOk(true)
    setTimeout(() => setOk(false), 2000)
  }
  const continuar = () => {
    onClick()
    setOk(false)
  }

  return (
    <Button color={color} fluid onClick={ok ? continuar : toggle} disabled={disabled} loading={loading}>
      {ok ? 'ok?' : label}
    </Button>
  )
}
/*
const BSide = ({ index, toggle }) => {
  return (
    <div>
      <div style={{ display: 'flex'}}>
        <Tarjeta>
          <Icon name="arrow left" size="large" />
        </Tarjeta>
        <Tarjeta>
          <h3>{index}</h3>
        </Tarjeta>
      </div>
      <div style={{ display: 'flex' }}>
        <Tarjeta>
          <Icon name="minus circle" color="red" size="large" />
        </Tarjeta>
        <Tarjeta>
          <Icon name="list" size="large" />
        </Tarjeta>
      </div>
    </div>
  )
}

const Tarjeta = ({ children }) => (
  <div style={{backgroundColor: 'white', color: 'black', padding: 4, borderRadius: 4}}>
    {children}
  </div>
)*/

export default TarjetaPedido
import React from 'react'
import { Grid, Header, Icon, Label } from 'semantic-ui-react'
import { clp, isEmpty, is_nori, reduceProducts, str } from '../utils'
import { is_delivery, typeIcon } from '../utils/OrderTypes'
import { payment_label } from '../utils/PaymentTypes'

const PedidoItem = ({ _id, index, channel, type, pay, payment, products, deliveryCost, user, motivo, small, onClick }) => {
  const _onClick = () => onClick(_id)
  return (
    <Grid.Row onClick={onClick ? _onClick : undefined} className={onClick ? "cs-pointer" : ""}>
      <Grid.Column width={small ? 3 : 2}>
        <div style={{ textAlign: 'center' }}>
          <Icon name={typeIcon(type)} size="big" />
          {!small && <div>{type}</div>}
          {onClick && <Icon name="edit" />}
        </div>
      </Grid.Column>
      <Grid.Column width={small ? 13 : 12}>
        <div>
          <Header>{index} - {isEmpty(user.name) ? 'Anonimo' : `${user.name} ${str(user.apellido)}`}</Header>
          <div>{user.address}</div>
          <div>{user.phone}</div>
          <Label.Group>
            <Label>{payment_label(payment)}</Label>
            {pay && <Label color="green">Pagado</Label>}
            {is_delivery(type) && <Label>Despacho {clp(deliveryCost)}</Label>}
            {is_nori(channel) && <Label color="red">App</Label>}
          </Label.Group>
          {!isEmpty(motivo) && <Label color="red">Motivo: {motivo}</Label>}
        </div>
      </Grid.Column>
      <Grid.Column width={2} style={{ textAlign: 'center' }}>
        <div>
          <Header>Total</Header>
          <div>{clp(products.reduce(reduceProducts, 0))}</div>
        </div>
      </Grid.Column>
    </Grid.Row>
  )
}

export default PedidoItem
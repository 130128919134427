import React, {Component, useState} from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Icon, Input, Item, Label, Modal } from 'semantic-ui-react'

import { galleryResource } from '../api'
import {clp, isEmpty, numeric, reduceProducts} from '../utils'
import { marcar_producto_favorito } from "../actions";
import LazyLoad from 'react-lazyload'


export const ItemProducto = (props) => {
  const { _id, indice, asset, title, detail, index, tags, price, quantity, products, favorito, extras,
    minus, plus, selectExtra, onClick, marcarFavorito, subproducts, subproducts_category, toggleSubProductos,
    quitarSubProducto } = props
    
  const onPlus = () => plus(indice)
  const onMinus = () => minus(indice)
  const imageSrc = isEmpty(asset) ? 'default.png' : galleryResource(asset)
  const onItemClick = () => onClick(_id)
  const favoritoClick = () => marcarFavorito(_id, !favorito)
  const subProductosClick = () => toggleSubProductos(subproducts_category, indice)
  const subProductoQuitarClick = (id) => quitarSubProducto(indice, id)

  const total_subproductos = subproducts ? subproducts.reduce(reduceProducts, 0) : 0

  return (
    <Item>
      <Item.Content>
        <Grid>
          <Grid.Column width="2">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 16 }}>
              <Item.Image src={imageSrc} size="tiny" />
              {marcarFavorito && <Button size="large" icon={favorito ? "star" : "star outline"} style={{ marginTop: 8 }} onClick={favoritoClick} type="button" />}
            </div>
          </Grid.Column>
          <Grid.Column width={onClick ? "10" : "14"}>
            <h3 className="cs-pointer" onClick={onClick && onItemClick}>{index} - {title}</h3>
            <Item.Meta>
              <span className='cinema'>{detail}</span>
            </Item.Meta>
            {!isEmpty(tags) && <Item.Extra>
              <Label>{tags}</Label>
            </Item.Extra>}
            <ul>
              {products === null ? null : products.map((sub, i) => (
                <li key={i}>
                  <small>{sub.title} {sub.detail ? '(' + sub.detail + ')' : ''}</small>
                </li>
              ))}
            </ul>
            <ul>
              {!subproducts ? null : subproducts.map((sub, i) => (
                <li key={i}>
                  <div style={{ display: 'flex',  justifyContent: 'space-between'}}>
                    <small>{sub.title} {sub.detail ? '(' + sub.detail + ')' : ''}</small>
                    <div>
                      <small>{clp(sub.price)}</small>
                      <Button icon="times" color="red" style={{ marginLeft: 4 }} size="tiny" onClick={() => subProductoQuitarClick(i)} type="button" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div><h4>{clp(price)}</h4></div>
            {minus && plus && <Item.Description>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button.Group size="big">
                  <Button color="green" icon="plus" onClick={onPlus} type="button" />
                  <Button.Or text={quantity} />
                  <Button color="red" icon="minus" onClick={onMinus} type="button" />
                  {!isEmpty(subproducts_category) && <Button color="yellow" icon="list" onClick={subProductosClick} type="button" />}
                </Button.Group>
                <strong style={{ textAlign: 'right' }}>${numeric((price + total_subproductos) * quantity)}</strong>
              </div>
            </Item.Description>}
            {selectExtra && extras && extras.length > 0 &&
              <Item.Description>
                <Button.Group widths={extras.length}>
                  {extras.map(v => (
                    <Button type="button" key={v.value} primary={v.selected} onClick={() => selectExtra(v.value, _id)}>{v.value}</Button>
                  ))}
                </Button.Group>
              </Item.Description>}
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width="3">
            {onClick &&
              <Button color="green" size="big" onClick={onItemClick}>
                <div style={{textAlign: 'center'}}>
                  <Icon name="plus" />
                  Agregar
                </div>
              </Button>
            }
          </Grid.Column>
        </Grid>
      </Item.Content>
    </Item>
  )
}

export const ModalProductos = ({ visible, toggle, productos, marcar_producto, onClick }) => {
  const [filtro, setFiltro] = useState('')
  const onChange = (e) => setFiltro(e.target.value)
  const onProductoClick = (v) => {
    onClick(v)
    toggle()
    setFiltro('')
  }

  const data = productos.filter(p => filtrarProductos(p, filtro)).sort(sortCount).sort(sortFavorito)

  return (
    <Modal open={visible} onClose={toggle} onOpen={toggle} centered={false}>
      <Modal.Header><Input value={filtro} onChange={onChange} fluid placeholder="Buscar Producto" /></Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          {/*<a key={p._id} href="" onClick={e => this.addProduct(e, p)} className="list-group-item list-group-item-action pr-0 pl-0">*/}
          <LazyLoad>
            <Item.Group divided>
              {data.map(p => (
                <ItemProducto key={p._id} {...p} onClick={onProductoClick} marcarFavorito={marcar_producto} />
              ))}
            </Item.Group>
          </LazyLoad>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  productos: state.productos
})

export const ProductosModal = connect(mapStateToProps, { 
  marcar_producto: marcar_producto_favorito
 })(ModalProductos)


function filtrarProductos({ title, index }, filter) {
  if (filter.trim() === '') {
    return true
  }
  return title.toLowerCase().indexOf(filter) !== -1 ||
    (!isNaN(filter) && index === parseInt(filter, 10)) || filter === '*'
}

function sortCount(a, b) {
  return (b.count ? b.count : 0) - (a.count ? a.count : 0)
}

function sortFavorito(a, b) {
  return (b.favorito ? 1 : 0) - (a.favorito ? 1 : 0)
}

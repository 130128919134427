import { get, post, put, del } from "./Methods";

//const path = '/api/v1'

export const sendEmail = (email) => post('/api/email', { email })
export const login = (body) => post('/api/login', body)
export const logout = () => del('/api/v1/logout')
export const obtenerSesion = () => get('/api/v1/session')
export const fijarSucursal = (sucursal) => post('/api/v1/user/sucursal', { sucursal })

export const fetchSucursales = (ids) => post('/api/v1/user/sucursales', { ids })
export const setSucursal = (sucursal) => post('/api/v1/user/sucursal', { sucursal })
export const validarPin = (pin) => post('/api/v1/sucursales/pin', { pin })

export const fetchPedidos = () => get('/api/v1/order/ALL')
export const fetchProductos = () => post('/api/v1/products')
export const ingresarPedido = (pedido) => put('/api/v1/order', pedido)
export const modificarPedido = (pedido) => post('/api/v1/order', pedido)
export const anularPedido = (id, pin) => post('/api/v1/order/anular', { id, pin })
export const pagarPedido = (id) => post('/api/v1/order/pay', { id })
export const avanzarPedido = (id) => post('/api/v1/pedido/avanzar', { id })
export const buscarPorTelefono = (telefono) => get('/api/v1/user/' + telefono)
export const buscarPuntos = (run) => get('/api/v1/user/:run/puntos'.replace(':run', run))
export const buscarPorRun = (run) => get('/api/v1/user/run/' + run)

export const fetchEmpleados = () => get('/api/v1/deliveryman')
export const fijarRepartidor = (id, man) => post('/api/v1/order/deliveryman', { id, man })

export const fetchGastos = () => get('/api/v1/gastos')
export const fetchConceptos = () => get('/api/v1/conceptos')
export const crearGasto = (body) => post('/api/v1/gastos', body)
export const eliminarGasto = (id) => del('/api/v1/gastos/' + id)
export const fetchComprasConceptos = () => get('/api/v1/backoffice/conceptos')
export const fetchCategoriasConceptos = () => get('/api/v1/backoffice/conceptos/categorias')

export const fetchJornadas = (offset, limit) => get('/api/v1/jornada', pagination(offset, limit))
export const jornadaActiva = () => get('/api/v1/jornada/activa')
export const crearJornada = () => put('/api/v1/jornada')
export const fetchPedidosJornada = (id) => get(`/api/v1/jornada/${id}/pedidos`)
export const fetchGastosJornada = (id) => get(`/api/v1/gastos/${id}`)
export const cerrarJornada = (id) => post('/api/v1/jornada/:jornada/cerrar'.replace(':jornada', id))

export const marcarFavorito = (_id, favorito) => post('/api/v1/productos/favorito', { _id, favorito })
export const fijarMotivo = (_id, motivo) => post('/api/v1/pedido/motivo', { _id, motivo })


export function isSuccess(res) {
  return res.status === 'success'
}

function pagination(offset, limit) {
  return {
    'X-LIMIT': limit,
    'X-OFFSET': offset
  }
}

export function galleryResource(value) {
  return resource(`gallery/${value}`)
}

function resource(value) {
  return `https://franquiciaskamisushi.cl/images/${value}`
}
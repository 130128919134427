import createReducer from './createReducer'
import { AGREGAR_PEDIDO, DESTROY_SESSION, SET_PEDIDOS } from '../actions/Types'


export const pedidos = createReducer([], {
  [SET_PEDIDOS]: (state, { pedidos }) => ([ ...pedidos ]),
  [DESTROY_SESSION]: () => ([]),
  [AGREGAR_PEDIDO]: (state, { pedido }) => {
    if (state.find(v => v._id === pedido._id)) {
      return state
    }

    return [ ...state, { ...pedido }]
  }
})
export const ALL = 'ALL'
export const WORKING = 'WORKING'
export const DONE = 'DONE'
export const DELIVERY = 'DELIVER'
export const RETIRO = 'RETIRO'
export const LOCAL = 'LOCAL'
export const COMPLETED = 'COMPLETED'
export const CANCELED = 'CANCELED'

export const STATUS_LIST = [{
  id: ALL,
  label: 'Todos',
  color: 'cs-bg-primary',
  bgColor: 'cs-bg-primary'
},
{
  id: WORKING,
  label: 'Cocinando',
  color: 'orange',
  bgColor: 'cs-color-working'
},
{
  id: DONE,
  label: 'Terminados',
  color: 'green',
  bgColor: 'cs-color-ready'
},
{
  id: DELIVERY,
  label: 'Despachos',
  color: 'violet',
  bgColor: 'cs-color-delivery'
},
{
  id: RETIRO,
  label: 'Retiros',
  color: 'brown',
  bgColor: 'cs-color-retiro'
},
{
  id: LOCAL,
  label: 'Restaurant',
  color: 'cs-color-retiro',
  bgColor: 'cs-color-retiro'
},
{
  id: COMPLETED,
  label: 'Facturados',
  color: 'bg-success'
},
{
  id: CANCELED,
  label: 'Anulado',
  color: 'red'
}]

const TYPE = 'TYPE'
const FLOW = [WORKING, DONE, TYPE, COMPLETED]

export function nextStatus(status, type) {
  let flow = FLOW.map(f => f === TYPE ? type : f)
  let index = flow.findIndex(s => s === status)
  if (index < flow.length - 1) {
    return flow[index + 1]
  }
  return status
}

export function statusLabel(status) {
  return statusvalue(status, 'label')
}

export function statusColor(status) {
  return statusvalue(status, 'color')
}

function statusvalue(_s, _v) {
  let _status = STATUS_LIST.find(s => s.id === _s)
  return _status !== null ? _status[_v] : ''
}

export function statusBgColor(status) {
  return statusvalue(status, 'bgColor')
}
import _ from 'lodash'
import { clp, fecha, reduceProducts, str } from "../utils"

export function cordova_impresora_modelos() {
  return cordova.epos2.getSupportedModels()
}

export function cordova_modelos_map(v) {
  return {
    id: v,
    text: v,
    value: v
  }
}

export async function cordova_imprimir(target, model = 'TM-T20', data) {
  const target_tcp = 'TCP:' + target

  try {
    const status = await cordova.epos2.getPrinterStatus()
    if (status.online && status.connection && status.isPrintable) {
      await imprimir()
    } else {
      await conectar_imprimir(target_tcp, model, data)
    }
  } catch (e) {
    await conectar_imprimir(target_tcp, model, data)
  }
}

async function conectar_imprimir(target, model, data) {
  try {
    await cordova.epos2.connectPrinter(target, model)
    return imprimir_comanda(data)
  } catch (e) {
    console.log('no conectado', e)
  }
}

async function imprimir_comanda({ sucursal, order }) {
  const { index, user, datetime, comment, deliveryCost, products } = order
  const total = products.reduce(reduceProducts, deliveryCost)
  const tip = total * 0.1

  try {
    //array, font, size, align, terminate
    await cordova.epos2.printText([_.padEnd(sucursal.nombre, 29) + fecha(datetime) + "\n"], 0, 1, 0, false)
    await cordova.epos2.printText([sucursal.direccion + "\n"], 0, 1, 0, false)
    await cordova.epos2.printText([`Pedido: ${index}\n`], 0, 2, 0, false)
    await cordova.epos2.printText([`${user.name} ${str(user.apellido)}\n`], 0, 2, 0, false)
    await cordova.epos2.printText([`${user.phone}\n`], 0, 2, 0, false)
    await cordova.epos2.printText([`${user.address}\n`, '\n'], 0, 2, 0, false)
    await cordova.epos2.printText([`Comentario: ${comment}\n`], 0, 1, 0, false)
    await cordova.epos2.printText([`-----------------------------------------------\n`], 0, 1, 0, false)
    await cordova.epos2.printText([`|N°  |Nombre               |Cantidad |Precio  |\n`], 0, 1, 0, false)
    await cordova.epos2.printText([`-----------------------------------------------\n`], 0, 1, 0, false)

    for (let p of products) {
      await cordova.epos2.printText([_.padEnd(p.index, 5), _.padEnd(p.title, 29), _.padEnd(p.quantity, 3), _.padEnd(clp(p.price), 10), '\n'], 0, 1, 0, false)

      if (p.subproducts) {
        for (let sub of p.subproducts) {
          await cordova.epos2.printText(['     ', _.padEnd(sub.title, 29), _.padEnd(sub.quantity, 3), _.padEnd(clp(sub.price), 10), '\n'], 0, 1, 0, false)
        }
      }
    }

    await cordova.epos2.printText([`-----------------------------------------------\n`], 0, 1, 0, false)
    await cordova.epos2.printText([`Despacho: ${clp(deliveryCost)}`, '\n'], 0, 1, 0, false)
    await cordova.epos2.printText([`Total Pedido: ${clp(total)}`, '\n', '\n'], 0, 2, 0, false)
    await cordova.epos2.printText([`Propina sugerida del 10%: ${clp(tip)}`, '\n'], 0, 1, 0, false)
    await cordova.epos2.printText([`Total: ${clp(tip + total)}`, '\n'], 0, 1, 0, true)
  } catch (e) {
    console.log('imprimir_comanda', e)
  }
}
export const DASHBOARD = '/'
export const ERROR = '/errors'
export const CREATE_ORDER = '/nueva/orden'
export const DETALLE_PEDIDO = '/pedido/:id'
export const CONFIGURATION = '/config'
export const JORNADAS = '/jornadas'
export const JORNADAS_DETAIL = '/jornadas/detalle/:id'
export const JORNADA_ACTUAL = '/jornadas/actual/:id'
export const COMPRAS = '/compras'
export const INGRESAR_COMPRA = '/compras/ingresar'
export const RESTAURANT = '/restaurant'
export const RESTAURANT_MESA = '/restaurant/mesa/:id'
export const SUCURSALES = '/sucursales'
import React, { useState } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSize } from 'react-sizeme'
import { Menu, Icon, Label, Card, Button, Container, Segment, Grid } from "semantic-ui-react";

import { CREATE_ORDER, DETALLE_PEDIDO } from '../utils/Routes'
import { ALL, WORKING, DONE, DELIVERY, RETIRO,  statusColor } from '../utils/Status'
import { DeliveryModal, SolicitarPin, Loading, AppHeader } from '../components'
import { anularPedido, pagarPedido, avanzarPedido, fijarMotivo } from '../api'
import { obtenerPedidos, limpiarError, fijar_filtro_estado } from "../actions";
import { typeIcon, DELIVERY as DELIVERY_TYPE, typeLabel } from '../utils/OrderTypes';
import moment from "../utils/Moment";
import { isEmpty, is_nori, str } from '../utils';
import { MensajeError } from '../components/Mensajes';
import { permisos_fs } from '../reducers/SessionReducer';
import Screen from './Screen';
import { filtro_estdo_fs } from '../reducers';
import TarjetaPedido from '../components/TarjetaPedido';


//const STATUS = [ALL, WORKING, DONE, DELIVERY, LOCAL]

const BREAKPOINT = 780



class Dashboard extends Screen {
  state = {
    pedidos: [],
    id_pedido: null,
    loading: false,
    current: null,
    modalPin: false,
    modalDelivery: false
  }

  componentDidMount() {
    this.fetch()
    this._timer = setInterval(this.fetch, 5000)
  }

  componentWillUnmount() {
    clearInterval(this._timer)
  }

  deliveryManSuccess = () => {
    this.startLoading()
    this.fetch().then(this.stopLoading).catch(this.onError)
  }

  fetch = async (v) => {
    await this.props.obtenerPedidos()
    return v
  }

  filter = (status) => {
    //this.setState({status})
    this.props.fijar_filtro_estado(status)
  }

  avanzar = async (_id, status, type) => {
    //console.log('avanzar', _id, status, type)
    if ((status === DONE || status === DELIVERY) && type === DELIVERY_TYPE) {
      this.setState({ id_pedido: _id })
      this.toggleDelivery()
    } else {
      return this.avanzarPedido(_id)
    }
  }

  avanzarPedido = (_id) => {
    return avanzarPedido(_id).then(this.fetch)
  }

  detalle = (_id) => {
    this.props.history.push(DETALLE_PEDIDO.replace(":id", _id))
  }

  toggleAnular = (_id) => {
    this._id = _id
    this.togglePin()
  }

  anular = async (pin, motivo) => {
    try {
      await anularPedido(this._id, pin)
      await fijarMotivo(this._id, motivo)
      await this.fetch()
    } catch (e) {
      console.error(e)
    }
  }

  pagado = (_id) => {
    return pagarPedido(_id).then(console.log).then(this.fetch)
  }

  statusLength = (status) => this.props.pedidos.filter(o => o.status === status).length

  togglePin = () => this.setState({ modalPin: !this.state.modalPin })
  toggleDelivery = () => this.setState({ modalDelivery: !this.state.modalDelivery })

  render() {
    const { id_pedido, loading } = this.state
    const { size, error, operador, estado } = this.props

    if (loading) return <Loading />
    
    const all = this.props.pedidos.length
    const working = this.statusLength(WORKING)
    const done = this.statusLength(DONE)
    const delivery = this.statusLength(DELIVERY)
    const retiro = this.statusLength(RETIRO)

    //const badge = status === 0 ? this.state.pedidos.length : this._statusLength(STATUS[status])
    return (
      <div>
        <AppHeader widths={6 - (operador ? 0 : 1)}>
          <HeaderItem active={estado === ALL} name="Todos" onClick={() => this.filter(ALL)} label={all} icon="bars" color="blue" width={size.width}/>
          <HeaderItem active={estado === WORKING} name="Cocinando" onClick={() => this.filter(WORKING)} label={working} icon="fire" color="orange" width={size.width}/>
          <HeaderItem active={estado === DONE} name="Terminados" onClick={() => this.filter(DONE)} label={done} icon="check" color="green" width={size.width}/>
          <HeaderItem active={estado === DELIVERY} name="Despachos" onClick={() => this.filter(DELIVERY)} label={delivery} icon="motorcycle" color="violet" width={size.width}/>
          <HeaderItem active={estado === RETIRO} name="Retiros" onClick={() => this.filter(RETIRO)} label={retiro} icon="home" color="brown" width={size.width}/>
          {operador && <Menu.Item onClick={() => this.go(CREATE_ORDER)}>
            <Icon name="plus" />
            Nuevo
          </Menu.Item>}
        </AppHeader>
        <Container fluid>
          <MensajeError content={error} />
          <Grid columns={size.width > BREAKPOINT + 300 ? 6 : 4} stackable>
            {this.props.pedidos.filter(o => o.status === estado || estado === ALL).sort(sortOrders).map(pedido => (
              <Grid.Column key={pedido._id}>
                <TarjetaPedido
                  {...pedido}
                  onClick={this.detalle}
                  onPagarClick={this.pagado}
                  onForwardClick={this.avanzar}
                  onCancelClick={this.toggleAnular}
                  disabled={!operador} />
              </Grid.Column>
            ))}
          </Grid>
        </Container>
        <DeliveryModal id_pedido={id_pedido} continuar={this.avanzarPedido} visible={this.state.modalDelivery} toggle={this.toggleDelivery} />
        <SolicitarPin onClick={this.anular} visible={this.state.modalPin} toggle={this.togglePin} tieneMotivo />
      </div>
    );
  }
}

const PedidoItem = ({ _id, index, type, user, datetime, channel, pay, status, onClick, onPagarClick, onCancelClick, onForwardClick, disabled, deliveryMan }) => {
  const [busy, toggle] = useState(false)
  const cardClick = () => onClick(_id)

  const pagar = async () => {
    try {
      toggle(true)
      await onPagarClick(_id)
    } catch (e) {
      console.error(e)
    } finally {
      toggle(false)
    }
  }

  const avanzar = async () => {
    try {
      toggle(true)
      const res = await onForwardClick(_id, status, type)
    } catch (e) {
      console.error(e)
    } finally {
      toggle(false)
    }
  }

  return (
    <Card fluid className={disabled ? "" : "cs-pointer"}>
      <Card.Content onClick={disabled ? undefined : cardClick}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}>
          <Label circular color={statusColor(status)} size="large">{index}</Label>
          <Icon name={typeIcon(type)} size="large" />
        </div>
        <Card.Header>{isEmpty(user.name) ? 'Anonimo' : `${user.name} ${str(user.apellido)}`}</Card.Header>
        <Card.Description>
          {isEmpty(user.address) ? '....' : user.address}
        </Card.Description>
        <Card.Meta>{moment(datetime).fromNow()}</Card.Meta>
        <Card.Meta>{typeLabel(type)}</Card.Meta>
        {(type === DELIVERY_TYPE && deliveryMan && deliveryMan !== null) ? <Card.Meta>{deliveryMan.nombre}</Card.Meta> : null}
        {pay && <Card.Meta>Pagado</Card.Meta>}
        <Channel value={channel} />
      </Card.Content>
     {!disabled && <Card.Content extra>
        <div className='ui tree buttons fluid'>
          <Button basic color='green' icon onClick={pagar} disabled={pay || busy} loading={busy}>
            <Icon name="money" />
          </Button>
          <Button basic color='red' icon onClick={() => onCancelClick(_id)} loading={busy} disabled={busy}>
            <Icon name="minus circle" />
          </Button>
          <Button basic primary icon onClick={avanzar} loading={busy} disabled={busy}>
            <Icon name="forward" />
          </Button>
        </div>
      </Card.Content>}
    </Card>
  )
}

const Channel = ({ value }) => {
  return is_nori(value) && <Label as='a' color='red' ribbon>App</Label>
}

const HeaderItem = ({ active, name, onClick, label, icon, color, width }) => (
  <Menu.Item active={active} name={name} onClick={onClick}>
    <Icon name={icon} />
    <div>
      <Label circular color={color}>{label}</Label>
      {width > 780 && <span>{name}</span>}
    </div>
  </Menu.Item>
)

function sortOrders(a, b) {
  return b.index - a.index
}

const mapStateToProps = (state) => {
  const { session, pedidos, estado } = state
  const sucursal = session.sucursales.find(s => s.id === session.sucursal)
  const operador = permisos_fs(state) >= 1024

  return {
    pedidos,
    error: estado.error,
    nombre_sucursal: sucursal ? sucursal.nombre : 'Wasabi',
    operador,
    estado: filtro_estdo_fs(state)
  }
}

export default connect(mapStateToProps, {
  obtenerPedidos, limpiarError, fijar_filtro_estado
})(withSize()(Dashboard))


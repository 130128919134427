import React from 'react'

function LoadingButton({ icon, loading, className }) {
  return (
    <button type="button" className={"btn btn-link w-100 " + className}>
      {loading ? <i className="fal fa-circle-notch fa-spin fa-2x"></i> :
        <i className={icon}></i>
      }
    </button>
  )
}

export default LoadingButton
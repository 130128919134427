import createReducer from './createReducer'
import { DESTROY_SESSION, FIJAR_JORNADAS, FIJAR_JORNADA_ACTIVA, FIJAR_JORNADA_DETALLE } from '../actions/Types'


export const jornadas = createReducer([], {
  [FIJAR_JORNADAS]: (state, { jornadas }) => [ ...jornadas ],
  [DESTROY_SESSION]: () => ([])
})

export const jornada_activa = createReducer(null, {
  [FIJAR_JORNADA_ACTIVA]: (state, { jornada }) =>  jornada,
  [DESTROY_SESSION]: () => null
})

const initialDetalle = {
  pedidos: []
}

export const jornada_detalle = createReducer(initialDetalle, {
  [FIJAR_JORNADA_DETALLE]: (state, { pedidos }) => ({
    ...state,
    pedidos: pedidos
  }),
  [DESTROY_SESSION]: () => ({ ...initialDetalle })
})

export function pedidos_jornada_from_state(state) {
  return state.jornada_detalle.pedidos
}

export function jornada_activa_fs(state) {
  return state.jornada_activa
}
import React from 'react'
import { connect } from 'react-redux'
import { Button, Header, Icon, Item, Label } from 'semantic-ui-react'
import { categorias_fs, conceptos_fs } from '../reducers'
import { clp } from '../utils'


const GastoItem = ({ id, id_concepto, concepto, monto, cantidad, descripcion, caja, eliminar, conceptos, categorias, loading }) => {
  const onClick = () => eliminar(id)
  const concepto_item = conceptos.find(v => v.id === id_concepto)
  const categoria = concepto_item && categorias.find(v => v.id === concepto_item.id_categoria)

  let nombre_concepto = concepto_item && concepto_item.nombre
  let nombre_categoria = categoria && categoria.nombre

  if (!nombre_concepto && concepto) {
    nombre_concepto = concepto.nombre_concepto
    nombre_categoria = concepto.tipo_concepto
  }
  
  return (
    <Item>
      <Item.Content style={{width:'100%'}}>
        <Item.Header>{nombre_concepto}</Item.Header>
        <Item.Meta>{nombre_categoria}</Item.Meta>
        <Item.Description>{descripcion}</Item.Description>
        <Item.Extra>
          <Label.Group>
            {caja && <Label color="green">Caja</Label>}
            <Label>{cantidad}</Label>
            <Label>{clp(monto)}</Label>
          </Label.Group>
        </Item.Extra>
        <Item.Description><Header as="h4">{clp(monto * cantidad)}</Header></Item.Description>
      </Item.Content>
      {eliminar && 
      <Item.Extra>
        <Button floated="right" color="red" onClick={onClick} disabled={loading}>Eliminar</Button>
      </Item.Extra>}
    </Item>
  )
}

const mapStateToProps = (state) => ({
  conceptos: conceptos_fs(state),
  categorias: categorias_fs(state)
})

export default connect(mapStateToProps)(GastoItem)
import {combineReducers} from 'redux'

import createReducer from './createReducer'
import { session } from './SessionReducer'
import { pedidos } from "./PedidosReducer";
import { jornadas, jornada_activa, jornada_detalle } from "./JornadaReducer";
import { SET_PRODUCTOS, FIJAR_ERROR, LIMPIAR_ERROR, FIJAR_EMPLEADOS,
  FIJAR_PRODUCTO_FAVORITO, 
  FIJAR_WINDOWS_SIZE,
  FIJAR_PRINTER_QUANTITY,
  FIJAR_PRINTER_TARGET,
  FIJAR_PRINTER_MODEL, TOGGLE_PRINTER_SSL, FIJAR_PRINTER_MODO, FIJAR_CONCEPTOS, FIJAR_FILTRO_ESTADO, FIJAR_PRINTER_NOMBRE} from "../actions/Types";
import { PRINTER_MODO_EPSON } from "../utils/Constants";
import { current_timestamp } from '../utils';
import { ALL } from '../utils/Status';


const productos = createReducer([], {
  [SET_PRODUCTOS]: (state, { productos }) => [ ...productos ],
  [FIJAR_PRODUCTO_FAVORITO]: (state, { _id, favorito }) => state.map(v => ({
    ...v,
    favorito: v._id === _id ? favorito : v.favorito
  }))
})

const empleados = createReducer([], {
  [FIJAR_EMPLEADOS]: (state, { empleados }) => [ ...empleados ]
})

const estadoInitialState = {
  error: '',
  windowSize: window.innerWidth,
  filtro_estado: ALL
}

const estado = createReducer(estadoInitialState, {
  [FIJAR_ERROR]: (state, { error }) => ({ ...state, error }),
  [LIMPIAR_ERROR]: (state) => ({ ...state, error: '' }),
  [FIJAR_WINDOWS_SIZE]: (state) => ({
    ...state,
    windowSize: window.innerWidth
  }),
  [FIJAR_FILTRO_ESTADO]: (state, action) => ({
    ...state, filtro_estado: action.estado
  })
})

const ajustesInitialState = {
  printer_target: '',
  printer_model: 'TM-T20',
  printer_quantity: 1,
  printer_ssl: true,
  printer_modo: PRINTER_MODO_EPSON,
  printer_nombre: ''
}

const ajustes = createReducer(ajustesInitialState, {
  [FIJAR_PRINTER_QUANTITY]: (state, { quantity }) => ({
    ...state, printer_quantity: quantity
  }),
  [FIJAR_PRINTER_TARGET]: (state, { target }) => ({
    ...state, printer_target: target
  }),
  [FIJAR_PRINTER_MODEL]: (state, { model }) => ({
    ...state, printer_model: model
  }),
  [TOGGLE_PRINTER_SSL]: (state) => ({
    ...state, printer_ssl: !state.printer_ssl
  }),
  [FIJAR_PRINTER_MODO]: (state, { modo }) => ({
    ...state, printer_modo: modo
  }),
  [FIJAR_PRINTER_NOMBRE]: (state, { nombre }) => ({
    ...state, printer_nombre: nombre
  })
})

const initialConceptosState = {
  categorias: [],
  data: [],
  timestamp: 0
}

const conceptos = createReducer(initialConceptosState, {
  [FIJAR_CONCEPTOS]: (state, { categorias, conceptos }) => ({
    categorias,
    data: conceptos,
    timestamp: current_timestamp()
  })
})

export default combineReducers({
  session, productos, pedidos, estado, jornadas, jornada_activa, empleados, jornada_detalle,
  ajustes, conceptos
})

export function sucursal_from_state({ session }) {
  return session.sucursales.find(v => v.id === session.sucursal)
}

export function is_window_small(state) {
  return state.estado.windowSize < 720
}

export function is_window_medium(state) {
  return state.estado.windowSize < 1000
}

const REFRESH_TIME = 1000 * 60 * 60 * 24

export function refrescar_conceptos_fs(state) {
  const conceptos = state.conceptos
  const timestamp = conceptos.timestamp
  return (current_timestamp() - timestamp) > REFRESH_TIME || conceptos.length === 0
}

export function conceptos_fs(state) {
  return state.conceptos.data
}

export function categorias_fs(state) {
  return state.conceptos.categorias
}

export function filtro_estdo_fs(state) {
  return state.estado.filtro_estado
}
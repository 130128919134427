import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Grid, Header, Icon, Label, Menu, Message } from 'semantic-ui-react';

import { crearJornada } from "../../api";
import { fijarJornadas, fijarJornadaActiva } from "../../actions";
import moment from '../../utils/Moment'
import {JORNADAS_DETAIL} from '../../utils/Routes'
import { permisos_fs } from '../../reducers/SessionReducer';
import { AppHeader } from '../../components';
import Screen from '../Screen';

class Jornadas extends Screen {
  state = {
    loading: true,
    mensaje: false,
    current: null
  }

  componentDidMount() {
    this.fetch().catch(console.error)
  }

  nueva = () => {
    this.startLoading()
    crearJornada().then(res => {
      if (res.status === 'success') {
        this.setState({ mensaje: true })
        return this.fetch()
      }
    }).catch(console.error).finally(this.stopLoading)
  }

  fetch = async () => {
    try {
      await this.props.fijarJornadas()
      await this.props.fijarJornadaActiva()
    } catch (e) {
      console.log(e)
    } finally {
      this.stopLoading()
    }
  }

  detalle = (id) => this.props.history.push(JORNADAS_DETAIL.replace(':id', id))

  render() {
    const { mensaje } = this.state
    const { jornadas, jornada_activa, operador } = this.props
    const actual = jornadas.find(v => v.id === jornada_activa)
    const data = jornadas.filter(v => v.id !== jornada_activa).sort(sortId)

    return (
      <div>
        <AppHeader>
          <Menu.Item>
            <Icon name="balance scale" />
            Jornadas
          </Menu.Item>
          {operador && <Menu.Item position="right" onClick={this.nueva} disabled={jornada_activa !== null}>
            <Icon name="plus" />
            Nueva Jornada
          </Menu.Item>}
        </AppHeader>
        <Container>
          {mensaje && <MensajeNuevaJornada />}
          <Grid verticalAlign="middle">
            {actual && <Jornada active={true} {...actual} onClick={() => this.detalle(actual.id)} />}
            {data.map(j => (
              <Jornada {...j} key={j.id} onClick={() => this.detalle(j.id)} />
            ))}
          </Grid>
        </Container>
      </div>
    )
  }
}

const MensajeNuevaJornada = () => (
  <Message color="yellow">
    Nueva jornada creada
  </Message>
)

const Jornada = ({ id, active, cierre, apertura, onClick }) => (
  <Grid.Row onClick={onClick} className="cs-pointer">
    <Grid.Column width={4}>
      <Label circular size="big" color={cierre ? 'green' : 'yellow'}>{id}</Label>
    </Grid.Column>
    <Grid.Column width={12}>
      {active && <Header>Jornada Actual</Header>}
      <div>
        Creada <strong>{moment(apertura).format("DD/MM/YYYY")}</strong> a las <strong>{moment(apertura).format('H:mm:ss')}</strong>.
        {cierre != null && <Closed cierre={cierre} />}
      </div>
    </Grid.Column>
  </Grid.Row>
)

function sortDates(a, b) {
  return moment(a).isBefore(b) ? -1  : 1
}

function sortId(a, b) {
  return a.id < b.id ? 1 : -1
}

function Closed({ cierre }) {
  const fecha = moment(cierre)
  return (
    <div>
      Cerrada el <strong>{fecha.format("DD/MM/YYYY")}</strong> a las <strong>{fecha.format('H:mm:ss')}</strong>
    </div>
  )
}

const mapStateToProps = (state) => ({
  jornadas: state.jornadas,
  jornada_activa:  state.jornada_activa,
  operador: permisos_fs(state) >= 1024
  
})
const mapDispatchToProps = (dispatch) => bindActionCreators({ 
  fijarJornadas,
  fijarJornadaActiva
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Jornadas)
import React, { Component } from 'react'
import { Form, Input, Segment } from 'semantic-ui-react';

import { buscarPuntos, buscarPorRun, isSuccess } from '../api'
import { rutValido, isEmpty } from "../utils";

const error = {
  content: 'El rut no es valido',
  pointing: 'below',
}

export default class InputRun extends Component {
  state = {
    puntos: 0,
    busy: false
  }

  onChange = (e) => {
    this.props.onChange(e)
    const value = e.target.value
    if (value.length > 6 && rutValido(value)) {
      this.toggle()
      const p1 = buscarPuntos(value)
      const p2 = buscarPorRun(value)
      Promise.all([p1, p2]).then(values => {
        const res1 = values[0]
        const res2 = values[1]
        if (isSuccess(res1) && isSuccess(res2)) {
          this.setState({ puntos: res1.data })
          this.props.onRunValido(res1.data, res2.data)
        }
      }).catch(console.error).finally(this.toggle)
    }
    this.setState({ puntos: 0 })
  }

  toggle = () => this.setState({ busy: !this.state.busy })

  render() {
    const { id, value } = this.props
    const { puntos, busy } = this.state
    const valido = isEmpty(value) || rutValido(value)
    

    return (
      <Form.Field error={!valido}>
        <label>Rut</label>
        <Input id={id} placeholder="Ingresa el rut sin guion" value={value} onChange={this.onChange}
          loading={busy} />
        <Puntos value={puntos} />
      </Form.Field>
    )
  }
}

const Puntos = ({ value }) => (
  <Segment compact>
    Puntos acumulados: {value}
  </Segment>
)
import React, { Component } from 'react';

import { INGRESAR_COMPRA } from '../../utils/Routes'
import { fetchGastos, isSuccess, eliminarGasto } from "../../api";
import { Button, Container, Icon, Item, Loader, Menu, Modal } from 'semantic-ui-react';
import GastoItem from '../../components/GastoItem';
import { fijar_conceptos } from '../../actions';
import { connect } from 'react-redux';
import { permisos_fs } from '../../reducers/SessionReducer';
import { AppHeader } from '../../components';


class Gastos extends Component {
  state = {
    gastos: [],
    modal: false,
    loading: true
  }

  async componentDidMount() {
    try {
      await this.fetch()
      await this.props.fijar_conceptos()
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  confirmar = (id) => {
    this._id_gasto = id
    this.toggle()
  }

  eliminar = async () => {
    try {
      this.toggle()
      this.setState({ loading: true })
      await eliminarGasto(this._id_gasto)
      await this.fetch()
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  create = () => this.props.history.push(INGRESAR_COMPRA)

  fetch = () => fetchGastos().then(res => {
    if (isSuccess(res)) {
      this.setState({ gastos: res.data })
    } else {
      this.setState({ gastos: [] })
    }
  })

  toggle = () => this.setState({ modal: !this.state.modal })

  render() {
    const loading = this.state.loading
    const operador = this.props.operador
    const data = this.state.gastos.sort(sortGastos)
    return (
      <div>
        <AppHeader>
          <Menu.Item>
            <Icon name="book" />
            Gastos
          </Menu.Item>
          {operador && <Menu.Item position="right" onClick={this.create}>
            <Icon name="plus" />
            Ingresar Gasto
          </Menu.Item>}
        </AppHeader>
        <Container>
          <Loader active={loading} inline='centered' />
          <Item.Group divided>
            {data.map(g => <GastoItem key={g.id} {...g} eliminar={operador ? this.confirmar : undefined} loading={loading} />)}
          </Item.Group>
        </Container>
        <Confirmar onClick={this.eliminar} visible={this.state.modal} toggle={this.toggle} />
      </div>
    )
  }
}

const sortGastos = (a, b) => b.id - a.id

const Confirmar = ({ onClick, visible, toggle }) => (
  <Modal open={visible} onClose={toggle}>
    <Modal.Header>Eliminar Gasto</Modal.Header>
    <Modal.Content>Para continuar eliminando el gasto presiona continuar</Modal.Content>
    <Modal.Actions>
      <Button onClick={toggle}>Volver</Button>
      <Button onClick={onClick} color="red">Continuar</Button>
    </Modal.Actions>
  </Modal>
)

const mapStateToProps = (state) => ({
  operador: permisos_fs(state) >= 1024
})

export default connect(mapStateToProps, { fijar_conceptos })(Gastos)
import React, { Component } from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { CREATE_ORDER, DETALLE_PEDIDO, INGRESAR_COMPRA,
  JORNADAS, INVENTORY, EMPLOYEE, CONFIGURATION,
  INVENTORY_CREATE, INVENTORY_DETAIL, JORNADAS_DETAIL,
  COMPRAS, RESTAURANT, RESTAURANT_MESA, PROFILE, DASHBOARD, SUCURSALES, JORNADA_ACTUAL} from './utils/Routes'
import {Dashboard, NuevoPedido, Login, Compras, DetalleJornada,
  Configuration, Employee, Inventory, Jornadas, IngresarCompra,
  InventoryCreate, InventoryDetail, Restaurant, Mesa, Profile} from './screens'
import { Loading } from './components'
import { isEmpty } from "./utils";
import { initialSetup, fijar_window_size } from "./actions";

import './App.css';
import Footer from './components/FooterTab';
import Sucursales from './screens/Sucursales';



class App extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    this.props.fijar_window_size()
    window.onresize = this.props.fijar_window_size
    if (this.props.authorized) {
      this.setup()
    }
  }

  setup = () => {
    this.setState({ loading: true })
    this.props.initialSetup().finally(() => this.setState({ loading: false }))
  }

  render() {
    if (!this.props.authorized) {
      return <Login setup={this.setup} />
    }

    if (this.state.loading) {
      return <Loading />
    }

    return (
      <Router>
        <div style={{ padding: '8em 2em' }}>
          <Switch>
            <Route path={CREATE_ORDER} component={NuevoPedido} />
            <Route path={DETALLE_PEDIDO} component={NuevoPedido} />
            <Route path={CONFIGURATION} component={Configuration} />
            <Route exact path={JORNADAS} component={Jornadas} />
            <Route path={JORNADAS_DETAIL} component={DetalleJornada} />
            <Route path={JORNADA_ACTUAL} component={DetalleJornada} />
            <Route exact path={COMPRAS} component={Compras} />
            <Route path={INGRESAR_COMPRA} component={IngresarCompra} />
            <Route exact path={RESTAURANT} component={Restaurant} />
            <Route path={RESTAURANT_MESA} component={Mesa}  />
            <Route path={SUCURSALES} component={Sucursales} />
            <Route component={Dashboard} />
          </Switch>
        </div>
        <Route exact path={DASHBOARD} component={Footer} />
        <Route exact path={COMPRAS} component={Footer} />
        <Route exact path={JORNADAS} component={Footer} />
        <Route exact path={JORNADA_ACTUAL} component={Footer} />
        <Route exact path={CONFIGURATION} component={Footer} />
        <Route exact path={SUCURSALES} component={Footer} />
      </Router>
    );
  }
}
/*
// define a handler
function doc_keyUp(e) {

  // this would test for whichever key is 40 and the ctrl key at the same time
  if (e.ctrlKey && e.altKey) {
    // call your function to do the thing
    console.log('key', e)
  }
  return false
}
// register the handler 
window.document.addEventListener('keyup', doc_keyUp, false);/*/

const mapStateToProps = ({ session }) => ({
  authorized: !isEmpty(session.token),
  sucursales: session.sucursales.sort((a, b) => a.id - b.id)
})

export default connect(mapStateToProps, { initialSetup, fijar_window_size })(App);


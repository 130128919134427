import React, { Component } from 'react'
import { Form, Header, Search } from 'semantic-ui-react'
import { buscarPorTelefono, isSuccess } from '../api'


export default class InputTelefono extends Component {
  state = {
    customers: [],
    selected: null,
    loading: false
  }

  search = (e) => {
    const phone = e.target.value
    //console.log('search', phone)
    if (phone.length > 7 && !this.state.loading) {
      this.toggle()
      buscarPorTelefono(phone).then(this.searchSuccess).catch(console.error).finally(this.toggle)
    }
    this.props.onChange(phone)
  }

  searchSuccess = (res) => {
    console.log(res)
    if (isSuccess(res)) {
      this.setState({ customers: res.data })
    }
  }

  toggle = () => this.setState({ loading: !this.state.loading})

  onSelect = ({ id }) => {
    //this.setState({customers: [], phone: telefono})
    //this.setState({ phone: telefono })
    const { telefono, nombre, apellido, direccion, id_direccion, despacho } =
      this.state.customers.find(v => v.id === id)
    
    this.props.onSelect({
      id,
      telefono,
      nombre: nombre !== null ? nombre : '',
      apellido: apellido !== null ? apellido : '',
      direccion: direccion !== null ? direccion : '',
      despacho: despacho !== null ? despacho : 0,
      id_direccion
    })
  }

  render() {
    const { customers, loading } = this.state

    const resultRenderer = (v) => (
      <Item {...v} />
    )

    const results = customers.map(v => ({
      title: `${v.nombre} ${v.apellido}`,
      description: v.telefono,
      content: v.direccion,
      id: v.id
    }))

    return (
      <Form.Field required={this.props.required}>
        <label>Numero de Teléfono</label>
        <Search
          type="number"
          placeholder="Numero de telefono" 
          showNoResults={false}
          loading={loading}
          value={this.props.value}
          onResultSelect={(e, data) =>
            this.onSelect(data.result)
          }
          onSearchChange={this.search}
          resultRenderer={resultRenderer}
          results={results} />
      </Form.Field>
    )
  }
}

const Item = ({ title, description, content }) => (
  <div>
    <Header>{title}</Header>
    <Header.Subheader>{description}</Header.Subheader>
    <Header.Content>{content}</Header.Content>
  </div>
)
import React from 'react';
import { connect } from 'react-redux'
import Screen from './Screen'
import moment from '../utils/Moment'
import { isEmpty, testEmail, int } from '../utils'
import { sessionLogin } from '../actions/SessionActions'
import { sendEmail } from '../api'
import { Button, Form, Grid, Header, Message, Segment, Image, Icon } from 'semantic-ui-react';
import { MensajeError } from '../components/Mensajes';


function sortSucursles(a, b) {
  return a.id - b.id
}

class Login extends Screen {
  state = {
    email:'',
    code: '',
    step: 0,
    loading: false,
    error: '',
    selected: null,
    sucursales: []
  }

  sendEmail = (e) => {
    e.preventDefault()
    this._clear()
    this.startLoading()
    sendEmail(this.state.email.trim()).then(r => {
      if (r.status === 'success') {
        const sucursales = r.data
        if (sucursales.length === 0) {
          this._onError("Usuario no autorizado")
        } else {
          this.setState({ 
            step: 1,
            loading: false,
            sucursales: sucursales.sort(sortSucursles),
            selected: sucursales[0].id
          })
        }
      } else {
        this._onError('Problemas enviando el correo, intente mas tarde.')
      }
    }).catch(this._onError)
  }

  login = (e) => {
    e.preventDefault()
    this.startLoading()
    const { email, code, selected } = this.state

    const data = {
      email: email.trim(),
      codigo: int(code),
      sucursal: int(selected)
    }
    this.props.sessionLogin(data).then(({ status, data }) => {
      if (status !== 'success') {
        this.setState({ error: data, loading: false })
      } else {
        this.props.setup()
      }
    }).catch(this._onError)
  }

  back = () => this.setState({step: 0, code: ''})

  _onError = (e) => {
    console.log(e.message ? e.message : e)
    //this.setState({error: 'Error interno, contacte al administrador', loading: false})
    this.setState({error: e.message ? e.message : e, loading: false})
  }
  _clear = () => this.setState({error: ''})
  _email = () => !testEmail(this.state.email) || this.state.loading
  _code = () => isEmpty(this.state.code) || isEmpty(this.state.selected) || this.state.loading
  onEmailChange = (e) => this.setState({ email: e.target.value })
  onCodeChange = (e) => this.setState({ code: e.target.value })
  onSucursalChange = (e, { value }) => this.setState({ selected: value })

  render() {
    const { email, code, step, loading, error, sucursales, selected } = this.state
    

    return (
      <Grid textAlign='center' style={{ height: '70vh', marginTop: 32 }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='black' textAlign='center'>
            <Image src={process.env.REACT_APP_LOGO} /> {process.env.REACT_APP_TITULO}
          </Header>
          <Form size='large' loading={loading}>
            <MensajeError content={error} />
            {step === 0 ?
              <EmailSegment email={email} onEmailChange={this.onEmailChange} onClick={this.sendEmail} /> :
              <CodeSegment code={code} onCodeChange={this.onCodeChange} onClick={this.login}
                onBackClick={this.back} sucursales={sucursales} sucursal={selected} onSucursalChange={this.onSucursalChange} />
            }
          </Form>
          <Message attached='bottom' warning>
            <Icon name='help' />
            {step === 0 ? 'Recibiras un correo con el código para ingresar.' : `Ingresa el codigo que enviamos al correo ${email}`}
          </Message>
          <Icon name="copyright" /> {process.env.REACT_APP_TITULO} - <small>{moment().format('YYYY')}</small>
        </Grid.Column>
      </Grid>
    )
  }
}

const EmailSegment = ({ email, onEmailChange, onClick }) => {
  const error = email.length > 0 && !testEmail(email)
  return (
    <Segment stacked>
      <Form.Input error={error} fluid icon='mail' iconPosition='left' placeholder='correo@proveedor.com' value={email} onChange={onEmailChange} />
      <Button color='black' fluid size='large'onClick={onClick} disabled={error || isEmpty(email)}>
        Continuar
      </Button>
    </Segment>
  )
}

const CodeSegment = ({ code, onCodeChange, onClick, onBackClick, sucursales, sucursal, onSucursalChange }) => {
  const error = isNaN(code)
  return (
    <Segment stacked>
      <Form.Select
        fluid
        value={sucursal}
        onChange={onSucursalChange}
        options={sucursales.map(v => ({ key: v.id, value: v.id, text: v.nombre }))}
        placeholder='Selecciona la Sucursal'
      />
      <Form.Input fluid icon='unhide' type="number" iconPosition='left'
        placeholder='Ingresa el código' error={error} value={code} onChange={onCodeChange} />
      <Button.Group vertical fluid labeled icon>
        <Button color='black' size='large' content="Ingresar" icon="sign in" onClick={onClick} disabled={error || isEmpty(code)} />
        <Button size="large" icon="chevron left" content="Volver" onClick={onBackClick} />
      </Button.Group>
    </Segment>
  )
}

const mapStateToProps = ({ session }) => ({
  authorized: !isEmpty(session.token)
})

const mapDispatchToProps = (dispatch) => ({
  sessionLogin: (email, code) => dispatch(sessionLogin(email, code))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
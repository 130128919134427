import createReducer from './createReducer'
import { CREATE_SESSION, DESTROY_SESSION, FIJAR_SESSION } from '../actions/Types'

const initialState = {
  email: '',
  token: '',
  permisos: 0,
  sucursal: 0,
  sucursales: []
}

export const session = createReducer(initialState, {
  [CREATE_SESSION]: (state, { token, email }) => ({
    ...initialState,
    email,
    token
  }),
  [FIJAR_SESSION]: (state, { permisos, sucursal, sucursales }) => ({
    ...state,
    permisos,
    sucursal,
    sucursales
  }),
  [DESTROY_SESSION]: () => ({...initialState})
})

export function permisos_fs(state) {
  return state.session.permisos
}

export function usuario_sucursales_fs(state) {
  return state.session.sucursales
}

export function usuario_fs(state) {
  return {
    email: state.session.email,
    permisos: state.session.permisos,
    id_sucursal: state.session.sucursal
  }
}
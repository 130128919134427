import React from 'react';
import Screen from '../Screen';
//import moment from '../utils/Moment'

export default class Mesa extends Screen {
  state = {
    products: []
  }

  add = (e) => {
    e.preventDefault()
  }

  render() {
    return (
      <div className="container pt-2">
        <div className="card mb-2">
          <div className="card-body d-flex justify-content-between align-items-center">
            <strong>Mesa {this.props.match.params.id}</strong>
            <button className="btn btn-primary" type="button">Abrir</button>
          </div>
        </div>
        <div className="form-group">
          <select className="form-control">
            <option>Mesero 1</option>
            <option>Mesero 2</option>
            <option>Mesero 3</option>
          </select>
        </div>
        <ul className="list-group list-group-flush mb-3">
          <li className="list-group-item text-right">
            <a href="">Agregar producto.</a>
          </li>
        </ul>

        <button className="btn btn-secondary btn-block" type="button" onClick={this.back}>Volver</button>
      </div>
    )
  }
}
